import React from "react";
import "./style.css";
import Roadmap1 from "../../assets/images/roadmap1.png";
import Roadmap2 from "../../assets/images/roadmap2.png";

export default function Roadmap() {
  return (
    <section id="roadmap" className="roadmap">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            <h1 className="t-shadow title-font">Roadmap</h1>
            <div className="timeline">
              <div className="outer">
                <div className="card" data-aos="fade-up">
                  <div className="info">
                    <h3 className="title">Phase 1 - Create Impactful Art</h3>
                    <p>
                      Create inspiring work that captures the essence of
                      relaxation without condemnation for the sake of
                      rejuvenation.
                    </p>
                  </div>
                </div>
                <div className="card" data-aos="fade-up">
                  <div className="info">
                    <h3 className="title">
                      Phase 2 - Trademark/Protecting the IP
                    </h3>
                    <p>
                      On Behalf of The Guy On The Couch, LLC, file Intent-to-use
                      Trademark applications for The Guy On the Couch [Club],
                      The Guy On The Couch, and Give the Grind A Break
                      (tagline). This will ensure protection of the considerable
                      Intellectual Property that is The Guy On The Couch brand.
                      It will also facilitate the opportunity to collaborate on
                      traits that call for added utility to the individual that
                      holds the NFT.
                    </p>
                  </div>
                </div>
                <div className="card" data-aos="fade-up">
                  <div className="info">
                    <h3 className="title">Phase 3 - Minting</h3>
                    <p>
                      Mint 10,000 unique members of The Guy On The Couch [Club]
                      for the very reasonable price of 0.5 Solana @
                      www.theguyonthecouchclub.com. These [Guys] may be relaxing
                      but their ability to accessorize hasn’t taken a day off.
                      The Rarity chart will follow after the mint.
                    </p>
                  </div>
                </div>
                <div className="card" data-aos="fade-up">
                  <div className="info">
                    <h3 className="title">
                      Phase 4 - Merchandising/ The Couch Coin
                    </h3>
                    <p>
                      Once minting is complete merchandising will begin on
                      www.theguyonthecouch.com. Clothing, Home goods,
                      accessories to start and the offerings will expand as we
                      partner our IP with other brands. 50% of profits will be
                      retained by the LLC for operational cost. The remaining
                      50% of profits will distributed to all NFT holders via The
                      Couch Coin crypto currency. The Couch Coin Crypto will be
                      airdropped to Holders wallets quarterly. Each NFT will
                      receive an equal amount of The Couch Coin. If you hold
                      multiple NFT from the collection your allocation will be X
                      amount of coins X number of NFT held in wallet.
                    </p>
                  </div>
                </div>
                <div className="card" data-aos="fade-up">
                  <div className="info">
                    <h3 className="title">Phase 5</h3>
                    <p>
                      Secondary Market Royalties allocation and Branding
                      opportunities A 10% royalty fee will be applied for all
                      sales on the secondary market. 100% of the royalties will
                      be applied to The Couch Coin crypto currency thereby
                      increasing the value of the coin. All branding will be
                      from the original 10,000 NFT stock. Any holder of an NFT
                      used for any product placement, branding or paid promotion
                      will received 25% of that deal.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 right">
            <img
              src={Roadmap1}
              alt="roadmap"
              className="img-fluid mb-3"
              data-aos="fade-up"
            />
            <img
              src={Roadmap2}
              alt="roadmap"
              className="img-fluid mb-3"
              data-aos="fade-up"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
