import React from "react";
import Header from "../Header";
import About from "../About";
import Footer from "../Footer";
import Roadmap from "../Roadmap";
import Faq from "../Faq";
import Discord from "../Discord";

export default function Home() {
  return (
    <>
      <Header />
      <About />
      <Roadmap />
      <Discord />
      <Faq />
      <Footer />
    </>
  );
}
