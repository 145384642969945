import React from "react";
import "./style.css";

export default function Faq() {
  return (
    <section className="faq py-5" id="faq">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="faq_title title-font t-shadow">FAQ</h1>
          </div>
        </div>

        <div className="accordion" id="accordionExample">
          <div
            className="accordion-item accordion_one  active_border"
            data-aos="fade-up"
          >
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                1. What is the Guy On The Couch [Club]?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                The Guy On The Couch [Club] is the first NFT to raise awareness
                on the lack mental self-care not just in the tech space, but in
                all walks of life.
              </div>
            </div>
          </div>

          <div className="accordion-item accordion_one" data-aos="fade-up">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                2. What is the total supply of The Guy On The Couch [Club]?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                There are only 10,000 guys on 10,000 couches to go around.
              </div>
            </div>
          </div>

          <div className="accordion-item accordion_one" data-aos="fade-up">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                3. What is the minting cost?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">0.5 Sol</div>
            </div>
          </div>

          <div className="accordion-item accordion_one" data-aos="fade-up">
            <h2 className="accordion-header" id="headeingfour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                4. What Solana wallet can I use?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headeingfour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Phanton, Sollet and Solflare.
              </div>
            </div>
          </div>

          <div className="accordion-item accordion_one" data-aos="fade-up">
            <h2 className="accordion-header" id="headeingfive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                5. Where can I sell my member of The Guy On The Couch [Club]?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headeingfive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">Magic Eden</div>
            </div>
          </div>
          <div className="accordion-item accordion_one" data-aos="fade-up">
            <h2 className="accordion-header" id="headeingsix">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                6. What blockchain is the project hosted on?
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="headeingsix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">Solana</div>
            </div>
          </div>
          <div className="accordion-item accordion_one" data-aos="fade-up">
            <h2 className="accordion-header" id="headeingseven">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                7. The Team
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              aria-labelledby="headeingseven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                The Guy On The Couch [Club] is the fever dream brainchild of CJ,
                a [Guy] who was literally on a couch, recovering from burnout
                while working in tech, when the idea hit him. The team that
                helped bring it to life are dozens of friends, advisors,
                designers, coders and assorted straight shooters. That team is
                now The Guy On The Couch [Club]. We hope to welcome you soon.
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </section>
  );
}
