import React, { useState, useEffect } from "react";
import Home from "../Page";
import "./style.css";

export default function Page() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <div>
      {loading ? (
        <div className="page-loader">
          <div className="spinner"></div>
          <div className="txt">The Guy On The Couch</div>
        </div>
      ) : (
        <Home />
      )}
    </div>
  );
}
