import React from "react";
import "./style.css";
import Gif from "../../assets/images/gif.gif";

export default function About() {
  return (
    <section
      id="about"
      className="h-100 w-100 about"
      style={{ boxSizing: "border-box" }}
    >
      <div className="content-8-3" data-aos="zoom-in">
        <div className="container-xxl mx-auto main">
          <div className="mx-auto d-flex flex-lg-row flex-column position-relative gap-md-4 gap-0">
            {/* <!-- Left Column --> */}
            <div
              className="left-column flex-lg-grow-1 d-flex flex-column align-items-center text-center"
              style={{ zIndex: "10" }}
            >
              <div className="frame">
                <img className="w-100" src={Gif} alt="img" />
              </div>
            </div>

            {/* <!-- Right Column --> */}
            <div className="right-column flex-lg-grow-1 text-start d-block">
              <h1 className="text-white t-shadow title-font">About Us</h1>
              <p className="text-gray-1 caption-text">
                The Guy On The Couch is the anti-hustle, semi-slacker, spirit
                animal we all need from time to time. That being said, we
                welcome you to The Guy On The Couch [Club]. The world’s FIRST
                NFT centered around mental self-care.
                <br className="d-md-block d-none" />
                <br />
                The [Guys] are literally 10,000 guys laying on 10,000 couches.
                They have hopes, dreams and a myriad of different interest. But
                right now they are taking a much needed break from the grind to
                mentally reset. This is going to be an amazing community-driven
                project because of the passion of its members. So take some time
                and check out the The Guys from The Guys On The Couch [Club] as
                they check in with themselves.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-shape-divider-top-1647973182">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <div className="custom-shape-divider-bottom-1648017657">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </section>
  );
}
