/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import App from "../../App";
import "./style.css";
import Logo from "../../assets/images/logo.png";
import LogoNavbar from "../../assets/images/logo.png";

export default function Header() {
  return (
    <>
      <section
        className="h-100 w-100 header"
        style={{ boxSizing: " border-box" }}
      >
        <div
          className="container-xxl mx-auto p-0  position-relative header-2-3"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          <nav className="navbar navbar-expand-lg navbar-dark">
            <a href="#">
              <img
                style={{ marginRight: "0.75rem" }}
                src={LogoNavbar}
                alt="navbar logo"
              />
            </a>
            <button
              className="navbar-toggler border-0"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#targetModal-item"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="modal-item modal fade"
              id="targetModal-item"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="targetModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div
                  className="modal-content border-0"
                  style={{ backgroundColor: "#141432" }}
                >
                  <div
                    className="modal-header border-0"
                    style={{ padding: "2rem", paddingBottom: "0" }}
                  >
                    <a className="modal-title" id="targetModalLabel">
                      <img
                        style={{ marginTop: "0.5rem" }}
                        src={LogoNavbar}
                        alt="logo"
                      />
                    </a>
                    <button
                      type="button"
                      className="close btn-close text-white"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div
                    className="modal-body"
                    style={{
                      padding: "2rem",
                      paddingTop: "0",
                      paddingBottom: "0",
                    }}
                  >
                    <ul className="navbar-nav responsive me-auto mt-2 mt-lg-0">
                      <li className="nav-item active">
                        <a
                          className="nav-link"
                          href="#"
                          style={{ color: "#E7E7E8" }}
                        >
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#about">
                          About Us
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#roadmap">
                          Roadmap
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#faq">
                          Faq
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="https://discord.com/invite/KzkCbxTanq"
                        >
                          Discord
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo">
              <ul className="navbar-nav me-auto mt-2 mt-lg-0">
                <li className="nav-item ">
                  <a className="nav-link" href="#">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#about">
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#roadmap">
                    Roadmap
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#faq">
                    Faq
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://discord.com/invite/KzkCbxTanq"
                  >
                    Discord
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://twitter.com/guyonthecouch77"
                  >
                    Twitter
                  </a>
                </li>
              </ul>
            </div>
          </nav>

          <div>
            <div className="mx-auto d-flex flex-lg-row flex-column hero">
              {/* <!-- Left Column --> */}
              <div
                className="left-column d-flex flex-lg-grow-1 flex-column align-items-lg-start text-lg-start align-items-center text-center"
                data-aos="fade-right"
              >
                <h1 className="title-text-big">
                  Welcome to <br /> The Guy On The Couch [Club]. <br /> Give the
                  grind a [break].
                  <p className="mt-3 text-caption text-gray-1">
                    The Guy On The Couch [Club] is the first NFT to raise
                    awareness on the lack mental self-care not just in the tech
                    space, but in all walks of life.
                  </p>
                </h1>
                <div className="d-flex flex-sm-row flex-column align-items-center mx-lg-0 mx-auto justify-content-center gap-3">
                  <App />
                </div>
              </div>
              {/* <!-- Right Column --> */}
              <div
                className="right-column text-center d-flex justify-content-center pe-0 px-3"
                data-aos="fade-left"
              >
                <img
                  id="img-fluid"
                  className="h-auto mw-100"
                  src={Logo}
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
